@use "vars" as *

.accordion
  width: 100%
  display: flex
  flex-direction: column

  &__tab
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    padding: 24px
    text-align: left
    font-size: 1.3rem
    color: $blue-900
    font-family: inherit
    cursor: pointer
    background: white
    border: solid 1px $gray-200

    @media (max-width: $md)
      font-size: 1.2rem

    @media (max-width: $sm)
      font-size: 1rem

    .remove
      display: none

    .add
      display: block

    &.active
      background: $gray-100

      .remove
        display: block

      .add
        display: none

  &__panel
    background: white
    border: solid 1px $gray-200
    display: none
    &__content
      padding: 48px

      @media (max-width: $md)
        padding: 24px

      @media (max-width: $sm)
        padding: 16px

    &.active
      height: auto
      display: block
