@use "vars" as *

.contact-bar
  width: 100%
  height: 48px
  position: fixed
  bottom: 0
  left: 0
  background: white
  z-index: 3
  align-items: center
  justify-content: center
  box-shadow: 0 0px 20px rgba(0,0,0, 0.15)
  gap: 16px
  display: none

  &__icon
    height: 100%
    display: flex
    align-items: center
    justify-content: center

    span
      font-size: 20px
      color: $blue-900

  &__item
    display: flex
    align-items: center

    a
      text-decoration: none
      color: $blue-900
      font-weight: 500

  @media screen and (max-width: $md)
    font-size: .85rem
    display: flex



