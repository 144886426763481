@use "vars" as *
  
.video
  margin-top: 104px
  width: 100%
  padding-bottom: 33.33%
  position: relative
  border-bottom: solid 1px $white
  overflow: hidden
  background: $blue-900

  &__player
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover

  @media screen and (max-width: $lg)
    padding-bottom: 50%

  @media screen and (max-width: $md)
    margin-top: 48px
    padding-bottom: 75%

  @media screen and (max-width: $sm)
    padding-bottom: 100%



