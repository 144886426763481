@use "vars" as *

button.btn
  font-weight: 500
  font-size: .8rem
  border-radius: 3px
  padding: 14px 22px
  transition: all .15s linear
  cursor: pointer
  outline: none
  text-transform: uppercase
  font-family: inherit
  
  &.outline
    background: none
    color: $blue-900
    border: solid 1px $blue-900

    &:hover
      background: $gray-200

  &.fill
    background: $blue-900
    color: white
    border: none

    &:hover
      background: darken($blue-900, 5%)
      color: white

  + .btn
    margin-left: 16px




