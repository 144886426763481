@use "vars" as *
  
.page-title
  margin-top: 80px
  width: 100%
  height: (192px + 48px)
  background:  $blue-900
  position: relative
  overflow: hidden
  
  video
    width: 100%
    height: 100%
    object-fit: cover
    filter: grayscale(100%)

  &__content
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: rgba($blue-900 , .95 )
    z-index: 1
    display: flex 
    align-items: center
    justify-content: center
    padding: 0 8px

  &__title
    text-align: center
    transform: translateY(12px)
    h1
      color: white

    h1 + p
      color: lighten($blue-900, 45)
      text-align: center
      margin-top: 12px

  @media screen and (max-width: $md)
    margin-top: 32px
    height: (160px + 48px)