@use "vars" as *

.banner
  width: 100%
  padding-bottom: 33.33%
  position: relative
  border-bottom: solid 1px $white
  overflow: hidden
  background: $blue-900

  @media (max-width: $lg)
    padding-bottom: 50%

  @media (max-width: $md)
    padding-bottom: 75%

  @media (max-width: $sm)
    padding-bottom: 100%

  &__player
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover

  &__content
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 90%
    max-width: $md
    background: rgba(#000, .25)
    padding: 24px

    &__logos
      margin: 16px auto 0
      max-width: 40%
      display: flex
      align-items: center
      justify-content: center
      gap: 32px

      @media (max-width: $md)
        max-width: 50%

      @media (max-width: $sm)
        max-width: 80%

      &__item
        img
          width: 100% 

    @media (max-width: $lg)
      max-width: 90%

    p
      text-align: center
      color: $white
      font-weight: 300
      font-size: 2rem
      text-shadow: 0 0 8px rgba($black, .5)
      
      span
        color: yellow

      @media (max-width: $md)
        font-size: 1.8rem

      @media (max-width: $sm)
        font-size: 1.6rem
