@use "vars" as *

.levels
  margin-bottom: 24px
  color: &gray-900

  &__top
    display: grid
    grid-template-columns: repeat(4, minmax(auto, 1fr))

    &__item
      display: flex
      flex-direction: column

      &__label
        height: 3rem
        display: flex
        align-items: center
        justify-content: center

      div[class*="levels__top__item__bar"]
        height: 1rem

  &__title
    width: 100%
    height: 3rem
    display: flex
    align-items: center
    justify-content: center
    font-weight: 700

  &__bottom
    display: grid
    grid-template-columns: repeat(4, minmax(auto, 1fr))
    gap: 16px

    @media (max-width: $md)
      grid-template-columns: repeat(2, minmax(auto, 1fr))

    @media (max-width: $sm)
      grid-template-columns: repeat(1, minmax(auto, 1fr))

    &__item
      display: flex
      flex-direction: column
      color: $black
      
      &__label
        height: 3rem
        display: flex
        align-items: center
        justify-content: center

      &__title
        font-weight: 700
        text-transform: uppercase
        width: 100%
        height: 3rem
        display: flex
        align-items: center
        justify-content: center
        font-size: 1.1rem

      div[class*="levels__bottom__item__card"]
        border-radius: 4px
        padding: 1rem
        height: 100%
        box-shadow: 0 0 10px rgba(#000000, 0.15)

.levels__bottom__item__card,
.levels__top__item__bar

  &--100
    background: #ffcdd2

  &--200
    background: #e57373

  &--300
    background: #f44336

  &--400
    background: #d32f2f
