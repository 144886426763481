@use "vars" as *

$padding: 0
  
.contact-page
  border-top: solid 1px  white

  &__container
    width: 100%
    display: grid
    grid-template-columns: 3fr 2fr
    gap: 48px
    align-items: center

  @media screen and (max-width: $lg)

    &__container
      grid-template-columns: 1fr 1fr

  @media screen and (max-width: $md)

    &__container
      display: flex
      flex-direction: column-reverse
      align-items: center

