@use "vars" as *

.gallery
  width: 100%
  display: grid
  grid-template-columns: repeat(3, 1fr)

  &__item
    position: relative
    padding-bottom: 56.25%
    overflow: hidden

    img
      position: absolute
      top: 0
      left: 0
      width: 100%
      object-fit: 50%

  @media (max-width: $md)
    display: none