@use "vars" as *

.navbar
  width: 100%
  height: 48px
  background: $blue-900

  &__container
    display: flex
    align-items: stretch
    justify-content: center

    ul 
      display: flex
      align-items: stretch
      justify-content: center

      > li:not(:last-child)
        border-right: solid 1px darken($blue-900, 5)

      > li:not(:first-child)
        border-left: solid 1px lighten($blue-900, 5)

      li
        cursor: pointer
        transition: all .15s linear
        a
          font-size: .9rem
          display: flex
          align-items: center
          justify-content: center
          height: 100%
          padding: 0 16px
          color: $white
          text-decoration: none
        
        &:hover
          background: darken($blue-900, 4)

        &.active
          background: darken($blue-900, 4)
          a
            color: yellow

  @media screen and (max-width: $md)
    display: none

    
