@use "vars" as *
  
.content
  display: flex
  flex-direction: column
  gap: 24px

  @media screen and (max-width: $md)
    gap: 16px

  h2
    + .important
      margin-top: 6px

