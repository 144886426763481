@use "vars" as *

$transition: all 0.15s linear

.header
  width: 100%
  position: fixed
  top: 0
  left: 0
  background: white
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3)
  z-index: 2

  &__content
    width: 100%
    height: 80px
    display: flex
    align-items: center
    justify-content: space-between
    transition: $transition

    &__logo
      width: 100%
      max-width: 140px
      transition: $transition

  &__right
    display: flex
    height: 100%
    align-items: center
    flex-wrap: nowrap
    gap: 32px

  &__contact-bar
    height: 100%
    align-items: center
    gap: 12px
    display: flex
    font-size: .85rem

    &__icon
      height: 100%
      display: flex
      align-items: center
      justify-content: center

      span
        font-size: 20px
        color: $blue-900

    &__item
      display: flex
      align-items: center

      a
        text-decoration: none
        color: $blue-900
        font-weight: 500

  &__brands
    display: flex
    flex-wrap: nowrap
    column-gap: 24px

    &__brand
      width: 100%
      max-width: 120px
      transition: $transition

    &__splitter
      width: 1px
      height: 40px
      background: $gray-300

  &__menu
    align-items: center
    justify-content: center
    color: $blue-900
    cursor: pointer
    display: none
    margin-left: 8px
    margin-right: 8px

    span
      font-size: 24px

  &.active

    .header

      &__content
        height: 48px

        &__logo
          max-width: 110px

      &__brands

        &__brand
          max-width: 90px

        &__splitter
          height: 30px

      @media screen and (max-width: $md)

        &__content

          &__logo
            max-width: 100px

        &__brands
          column-gap: 8px

          &__brand
            max-width: 80px

  @media screen and (max-width: $md)

    &__content

      &__logo
        max-width: 100px

    &__brands
      column-gap: 8px

      &__splitter
        height: 30px

      &__brand
        max-width: 80px

    &__menu
      display: flex

    &__contact-bar
      display: none
