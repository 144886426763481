@use "vars" as *
@use "mixins" as *

.highlights
  width: 100%
  margin-top: -48px
  z-index: 1
  position: relative
  @extend  .section-mb

  &__content
    width: 100%
    background: $blue-900
    border-radius: $radius
    display: flex
    align-items: stretch
    overflow: hidden
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3)

    > div:not(:last-child)
      border-right: solid 1px darken($blue-900, 5)

    > div:not(:first-child)
      border-left: solid 1px lighten($blue-900, 5)

    &__item
      width: 33.33%
      padding: 40px
      display: flex
      flex-direction: column
      justify-content: space-between
      cursor: pointer
      transition: all 0.15s linear

      &:hover
        background: darken($blue-900, 5)

      h3
        color: white

        span
          color: lighten($blue-900, 45)
          margin-left: 8px

      p
        color: lighten($blue-900, 45)

      p + span
        color: white
        text-transform: uppercase
        display: flex
        align-items: center

        span
          color: lighten($blue-900, 45)
          margin-right: 8px
          font-size: 1rem

    &__item-important
      @extend .highlights__content__item
      space-between: normal
      padding: 0 

      h3
        width: 100%
        background: white
        color: $blue-900
        padding: 40px
        font-weight: 700

      p
        padding: 40px

        + span
          display: none

    @media screen and (max-width: $md)
      flex-direction: column

      > div:not(:last-child)
        border-right: none
        border-bottom: solid 1px darken($blue-900, 5)

      > div:not(:first-child)
        border-left: none
        border-top: solid 1px lighten($blue-900, 5)

      &__item
        width: 100%
        padding: 24px
        flex-direction: column
        flex-wrap: nowrap
        row-gap: 24px
        border: none

      &__item-important

        h3
          padding: 0
          background: none
          color: white
          font-weight: 400

        p
          padding: 0 

          + span
            display: flex

    @media screen and (max-width: $md)

      &__item
        row-gap: 16px
    
