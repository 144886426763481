@use "vars" as *
@use "mixins" as *
  
.sample
  @extend .section-mb

  &.mb
    @extend .section-mb

  &.mt
    @extend .section-mt

  &__content
    display: flex
    gap: 32px

    > div
      width:  50%
      display: flex

  &__card
    width: 100%
    background: $white
    padding-bottom: 75%
    box-shadow: 0px 0px 8px rgba($black, 0.05)
    border-radius: $radius
    position: relative
    overflow: hidden

    img
      position: absolute
      width: 100%
      height: 100%
      object-fit: cover

  @media (max-width: $md)

    &__content
      flex-direction: column

      > div
        width: 100%

  @media (max-width: $sm)

    &__content
      gap: 24px