@use "vars" as *
  
.section-mb
  margin-bottom: 96px

  @media screen and (max-width: $lg)
    margin-bottom: 80px

  @media screen and (max-width: $md)
    margin-bottom: 64px

  @media screen and (max-width: $sm)
    margin-bottom: 48px

.section-mt
  margin-top: 96px

  @media screen and (max-width: $lg)
    margin-top: 80px

  @media screen and (max-width: $md)
    margin-top: 64px

  @media screen and (max-width: $sm)
    margin-top: 48px