@use "vars" as *

div[class*="wrapper"]
  width: 100%
  height: 100%
  display: grid
  justify-content: center
  padding: 0 16px

.wrapper
  
  &--xl
    grid-template-columns: minmax(0, $xl)

  &--lg
    grid-template-columns: minmax(0, $lg)

  &--md
    grid-template-columns: minmax(0, $md)

  @media screen and (max-width: $md)
    padding: 0 16px

  @media screen and (max-width: $sm)
    padding: 0 8px
