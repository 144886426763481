@use "vars" as *

section.container
  width: 100%
  padding: 96px 0

  @media (max-width: $lg)
    padding: 80px 0

  @media (max-width: $md)
    padding: 64px 0

  @media (max-width: $sm)
    padding: 48px 0

  &.white
    background-color: $white

  &.gray
    background-color: $gray-100
