@use "vars" as *

.footer
  width: 100%
  background: $blue-900
  margin-top: -64px
  padding: 128px 24px 112px
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center
  gap: 24px
  color: white
  font-size: .9rem

  &__icon
    width: 58px
    height: 58px

    img
      width: 100%

  p + p
    color: lighten($blue-900, 45)

  a
    color: lighten($blue-900, 45)
    text-decoration: none

  @media screen and (max-width: $md)
    gap: 16px