@use "vars" as *

.contact-form
  display: flex
  flex-direction: column
  gap: 16px

  label
    font-size: .95rem
    font-weight: 500
    color: $blue-900

  label + input, label + textarea
    margin-top: 8px

  input, textarea
    width: 100%
    height: 58px
    border: solid 1px $gray-300
    padding: 0 16px
    border-radius: $radius / 2
    font-family: inherit
    font-size: 1.05rem
    color: $gray-700

    &::placeholder
      color: $gray-400

    @media screen and (max-width: $sm)
      height: 48px

  textarea
    height: 128px
    padding: 16px 24px

  &__split
    display: flex
    gap: 16px
    
    .form-group
      width: 100%

    @media screen and (max-width: $xl)
      flex-direction: column

    @media screen and (max-width: $md)
      flex-direction: row
      gap: 8px

    @media screen and (max-width: $sm)
      flex-direction: column
      gap: 16px

