@use "vars" as *

@forward "reset"

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap")
@import url("https://fonts.googleapis.com/icon?family=Material+Icons")

body
  background: $gray-50
  font-family: "Roboto", sans-serif
  font-weight: 400
  color: $gray-800
  font-size: 1.05rem


h1
  font-size: 2.5rem
  font-weight: 900

  + p
    font-weight: 500
    font-size: 1.25rem

  @media screen and (max-width: $md)
    font-size: 2.25rem
    + p
      font-size: 1.2rem

  @media screen and (max-width: $sm)
    font-size: 2rem
    + p
      font-size: 1.1rem

h2
  font-size: 2.25rem
  color: $blue-900
  font-weight: 900

  @media screen and (max-width: $xl)
    font-size: 2rem

  @media screen and (max-width: $md)
    font-size: 1.8rem

  @media screen and (max-width: $sm)
    font-size: 1.6rem

h3
  font-size: 1.4rem

  span
    font-size: 1rem

  @media screen and (max-width: $md)
    font-size: 1.3rem

    span
      font-size: 0.9rem

  @media screen and (max-width: $sm)
    font-size: 1.2rem

h4
  font-size: 1.5rem
  color: $blue-900

  @media (max-width: $md)
    font-size: 1.2rem

  @media (max-width: $sm)
    font-size: 1rem

p
  line-height: 130%

  &.important
    font-size: 1.4rem
    line-height: 110%
    color: $blue-secondary

    @media screen and (max-width: $xl)
      font-size: 1.2rem

    @media screen and (max-width: $md)
      font-size: 1.2rem

    @media screen and (max-width: $sm)
      font-size: 1.1rem

.center
  text-align: center

.blue
  &--primary
    color: $blue-900

small
  font-size: 0.9rem

strong
  font-weight: 700

.color-primary
  color: $blue-900

.button-container
  > button:nth-child(2)
    margin-left: 8px

figure
  img
    width: 100%
    height: auto
    object-fit: cover

  figcaption
    font-size: 1rem
    line-height: 130%
    font-style: italic
    color: $gray-600
    margin-top: 24px
