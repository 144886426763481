@use "vars" as *

.flex
  display: flex
  align-items: center
  gap: 64px

  @media (max-width: $md)
    gap: 24px
    flex-direction: column

  &__item
    width: 50%
    display: flex
    align-items: center
    justify-content: center

    img
      width: 100%

    @media (max-width: $md)
      width: 100%

  &.reverse

    @media (max-width: $md)
      flex-direction: column-reverse
