@use "vars" as *

.devices
  background: $white
  
  &__container
    display: grid
    grid-template-columns: repeat(5, 1fr)
    padding: 16px 0

    @media (max-width: $md)
      grid-template-columns: repeat(2, 1fr)

  &__item
    display: flex
    align-items: center
    justify-content: center

    img
      width: 100%