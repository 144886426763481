$gray-50: #fafafa
$gray-100: #f5f5f5
$gray-200: #eeeeee
$gray-300: #e0e0e0
$gray-400: #bdbdbd
$gray-500: #9e9e9e
$gray-600: #757575
$gray-700: #616161
$gray-800: #424242
$gray-900: #212121

$black: #000000
$white: #ffffff

$blue-50: #e3f2fb
$blue-300: #66b5ee
$blue-400: #46a6eb
$blue-500: #2697e8
$blue-900: #004a98

$sm: 375px
$md: 768px
$lg: 1024px
$xl: 1180px

$radius: 8px

$blue-secondary: #5eb3e4
