@use "vars" as *
@use "mixins" as *

.contact-map
  @extend .section-mt

  &__container
    width: 100%
    padding-bottom: 33.33%
    position: relative

    iframe
      width: 100%
      height: 100%
      position: absolute
      top: 0
      left: 0

  @media screen and (max-width: $lg)

    &__container
      padding-bottom: 56.25%

  @media screen and (max-width: $md)

    &__container
      padding-bottom: 75%

