@use "vars" as *

.contact-info
  display: grid
  grid-template-columns: 48px auto
  align-items: center
  gap: 16px

  &__icon
    color: white
    background: $blue-900
    width: 44px
    height: 44px
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center

    span
      font-size: 20px

  &__text
    width: auto

    p + p 
      color: $blue-900
      font-weight: 500

    span
      margin: 0 8px
  
  @media (max-width: $lg)
    gap: 8px

