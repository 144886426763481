@use "vars" as *

.list
  
  li
    color: $blue-900
    line-height: 120%
    display: flex

    ul
      padding-top: 16px

    .material-icons
      margin-top: -0.2rem

    + li
      margin-top: 16px

    a
      color: $blue-900

