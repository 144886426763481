@use "vars" as *

.aside
  top: 0
  right: -100%
  z-index: 9999
  position: fixed
  width: 100%
  height: 100%
  background: rgba($blue-900, .95)
  display: none
  align-items: center
  justify-content: center
  text-align: center
  transition: right .15s linear

  &.active
    right: 0

  @media (max-width: $md)
    display: flex

  &__close
    position: absolute
    top: 16px
    right: 16px
    width: 48px
    height: 48px
    color: $white
    display: flex
    align-items: center
    justify-content: center
    span
      font-size: 24px

  ul li a 
    display: block
    padding: 8px
    color: $white
    text-decoration: none
    font-size: 1.5rem
    transition: all .15s linear

  ul li.active a
     color: yellow