@use "vars" as *

.split
  width: 100%
  display: flex
  align-items: stretch
  background: $gray-100
  z-index: 1
  position: relative

  &__left
    width: calc( ( (100% - $xl) / 2) + ($xl / 3) )
    position: relative
    
    img
      position: absolute
      width: 100%
      height: 100%
      object-fit: cover

  &__right
    width: calc($xl/3 * 2)
    padding: 96px 0 96px 64px

  &.right
    flex-direction: row-reverse
    background: white
    box-shadow: 0 20px 20px rgba(0,0,0, 0.05)
    z-index: 0

    .split__right
      padding: 96px 64px 96px 0

  @media (max-width: $xl)

    &__left, &__right
      width: 50%

    &__right
      padding: 80px 32px

    &.right

      .split__right
        padding: 80px 32px

  @media (max-width: $md)
    flex-direction: column

    &__left, &__right
      width: 100%

    &__left
      padding-bottom: 56.25%

    &__right
      padding: 64px 24px

    &.right
      flex-direction: column

      .split__right
        padding: 64px 24px

  @media (max-width: $sm)

    &__right
      padding: 48px 24px

    &.right

      .split__right
        padding: 48px 24px
