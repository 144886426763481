@use "vars" as *
@use "mixins" as *

.contact
  @extend .section-mt

  &__container
    background: white
    display: flex
    align-items: stretch
    border-radius: $radius
    overflow: hidden
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15)

    > div
      width: 50%

  &__map
    display: flex
    align-items: stretch

    iframe
      flex-grow: 1
      border: none
  
  &__info
    padding: 96px 64px 96px 64px

  @media (max-width: $xl)

    &__info
      padding: 80px 32px

  @media (max-width: $md)

    &__container
      flex-direction: column-reverse

      > div
        width: 100%

    &__map
      padding-bottom: 75%
      position: relative

      iframe
        position: absolute
        width: 100%
        height: 100%

    &__info
      padding: 64px 24px

  @media (max-width: $sm)
      
      &__info
        padding: 48px 24px
        



